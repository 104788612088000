import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useMemberStore = defineStore({
    id: 'MemberStore',
    state() { 
        return {
            list: [],
            filter: 'Show active',
            search: '',
            errors: [] ,
            sort: {
                key: '',
                isAsc: false,
            }
        }
    },
    getters: {
        count: function(state){
            return state.list.length
        },
        sortedItems (state) {
            let archivedParam = -1;
            if ( state.filter == 'Show active') archivedParam = 0;
            else if ( state.filter == 'Show archived' ) archivedParam = 1;
            if ( !state.list ) return state.list
            for(let i = 0; i < state.list.length; i++ ){
                state.list[i].role = state.list[i].ur == 0 ? 'User' : 'Admin'
                state.list[i].openMenu = false
                console.log(i + ' ' + state.list[i].role)
            }
            const list = state.list.filter((el) => { 
                return (el.t.toLowerCase().indexOf(state.search.toLowerCase()) != -1 ||
                    el.e.toLowerCase().indexOf(state.search.toLowerCase()) != -1 )
                    && (el.a == archivedParam || archivedParam == -1)
                });

            if (this.sort.key) {
                list.sort((a, b) => {
                    a = a[this.sort.key]
                    b = b[this.sort.key]
                    return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
                });
            }
            return list;
        },
        filterValue: function(state){
            return state.filter
        },
        searchValue: function(state){
            return state.search
        }
    },
    actions:{
        async changeBillableRate(uk, br){
            this.update({uk: uk}, { br: br } )
            for(let i=0;i<this.list.length;i++){
                if ( this.list[i].uk == uk) {
                    this.list[i].br = br
                }
            }
        },
        sortedClass (key) {
            this.$patch((state) => {
                return state.sort.key === key ? `sorted ${state.sort.isAsc ? 'asc' : 'desc' }` : '';
            });
        },
        sortBy (key) {
            this.$patch((state) => {
                state.sort.isAsc = state.sort.key === key ? !state.sort.isAsc : false;
                state.sort.key = key;
            });
        },
        async changeSearch(search){
            console.log('Member changeSearch: ' + search)
            this.$patch((state) => {
                state.search = search;
            })
        },
        async changeFilter(filter){
            console.log('Member changeFilter: ' + filter)
            this.$patch((state) => {
                state.filter = filter;
            })
        },
        async changeSort(sort){
            this.$patch((state) => {
                state.sort = sort;
            })
        },
        async listing(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/users');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        
        async insert(dataInsert){
            console.log(dataInsert['email'])
            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    console.log('Insert Method: ' + dataInsert.title)
                    const response = await ApiCall.post(
                        'ws/{wk}/users',
                        dataInsert
                    )
                    if ( response.data.message )
                        this.$patch((state) => {
                            state.list.push(response.data.message)
                        })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async update(member, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.post( 'ws/{wk}/users/' +member.uk,
                    form
                )
                return response
            }
        },
        async remove(member) {
            this.$patch((state) => {
                for( let i = 0; i < state.list.length; i++){
                    if ( state.list[i].uk == member.uk) {
                        console.log('Archive member: ' + state.list[i].uk)
                        state.list[i].a = (state.list[i].a == 1 ? 0 : 1)
                        break
                    }
                }
            })
            ApiCall.options().then(response => {
                console.log(response)
                ApiCall.delete('ws/{wk}/users/' + member.uk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            })
        }
    }
})